/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "@ionic/angular/css/normalize.css";
@import "@ionic/angular/css/structure.css";
@import "@ionic/angular/css/typography.css";
@import '@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "@ionic/angular/css/padding.css";
@import "@ionic/angular/css/float-elements.css";
@import "@ionic/angular/css/text-alignment.css";
@import "@ionic/angular/css/text-transformation.css";
@import "@ionic/angular/css/flex-utils.css";
@import "~quill/dist/quill.snow.css";
@import "./global-sass-variables.scss";

html *
{
   // color: var(--pal-text-color);
   font-family: 'Open Sans Variable', sans-serif !important;
}
// ::-webkit-scrollbar {
//     overflow-x: scroll;
//     -webkit-overflow-scrolling: touch;
//     -ms-overflow-style: none;  // IE 10+
//     scrollbar-width: none;  // Firefox
//     scroll-behavior: smooth;

//     &::-webkit-scrollbar {
//       display: none; // Safari and Chrome
//     }
// }
// .scroller {
//     overflow-x: scroll;
//     -webkit-overflow-scrolling: touch;
//     -ms-overflow-style: none;  // IE 10+
//     scrollbar-width: none;  // Firefox
//     scroll-behavior: smooth;

//     &::-webkit-scrollbar {
//       display: none; // Safari and Chrome
//     }
// }

.clickable {
    cursor: pointer;
    text-decoration: underline;
    color: var(--ion-color-primary);
}

.vertical-center-in-col {
    display: flex;
    align-items: center;
}

ion-button {
    text-transform: none;
}

bible-reference {
    text-decoration: underline;
    cursor: pointer;
    font-weight: bold;
    // margin: .5rem;
    // color: #3880ff;
}

page-reference {
    text-decoration: underline;
    cursor: pointer;
    font-weight: bold;
    // margin: .5rem;
    // color: #3880ff;
}

ion-title {
    font-weight: bold;
    color: var(--pal-dark-blue);
    span {
        color: var(--pal-dark-blue); 
    }
}
ion-modal::part(open) {
    .modal-wrapper {
      border-radius: var(--pal-border-radius);
    }
  }
  
  ion-modal::part(content) {
    // height: var(--current-modal-height) !important;
    // width: var(--current-modal-width) !important;
    // max-width: 50%;
    max-height: 100%;
    border-radius: var(--pal-border-radius) !important;
    overflow-y: scroll;
  }
.pal-button {
    margin: 0;
    color: white;
    font-size: 1rem;
  }
.pal-button::part(native) {
    height: 3.5rem;
    border-radius: var(--pal-border-radius);
}

.jacobi-auth-flow {
    color: var(--pal-dark-grey) !important;
    // font-size: 1.25rem;
    .apple-sign-in-button::part(native) {
        border-radius: var(--pal-border-radius) !important;
    }
    .facebook-sign-in-button::part(native) {
        border-radius: var(--pal-border-radius) !important;
    }
    .outer-row {
        
            background-image: url('https://storage.googleapis.com/preacherpal.us/images/bible.png'); /* sets the background image */
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            border-radius: var(--pal-border-radius);
            .content-col {
                .interface-col {
                    // height: 70vh;
                    // width: 50vh !important;
                    background-color: white;
                    border-radius: var(--pal-border-radius);
                    padding-top: 2rem;
                    padding-bottom: 3rem;
                    padding-left: 3rem;
                    padding-right: 3rem;
                    .logo-with-words {
                        margin-bottom: 1rem;
                    }
                }
                
            }
        
        
        // background-size: contain;
        // background-position: center;
    }
   
    
    .jacobi-button {
        margin: 0;
        color: white;
        // font-size: 1.25rem;
        width: 100%;
    }
    .jacobi-button::part(native) {
        height: 3.5rem;
        border-radius: var(--pal-border-radius);
    }
    .ghost-auth-secondary::part(native) {
        border-style: solid;
        background: white;
        border-width: 1px;
        color: var(--ion-color-primary);
        box-shadow: none;
    }
    // .outer-row {
    //     .content-col {
    //         color: var(--pal-dark-grey) !important;
    //     }
    // }
    
    
}

.input-item {
    // font-size: 1.25rem;
    min-height: 3.5rem !important;
    height: 3.5rem !important;
    border: 1px solid var(--ion-color-primary) !important;
    border-radius: var(--pal-border-radius) !important;
    --highlight-background: none;
    --highlight-height: 0px !important;
    --padding-start: 1rem !important;
    // --placeholder-color: var(--pal-text-light);
    ion-icon {
        margin: 0 !important;
        font-size: 2rem;
        align-self: center;
        color: var(--pal-text-light) !important;
    }
    ion-label {
        font-size: 1rem !important;
        margin: 0 !important;
        --color: var(--pal-text-light) !important;
        font-weight: 600;
    }
    ion-input {
        //--padding-start: 1rem !important;
        // padding-left: 1rem !important;
        margin: 0 !important;
        color: var(--pal-text-color) !important;
    }
}
// .input-item::part(native) {
//     padding-inline-start: 0 !important;
// }
.alerts {
    .alert-wrapper {
        border-radius: var(--pal-border-radius);
    }
}
ion-popover::part(content) {
    border-radius: var(--pal-border-radius);
}

ion-card {
    border-radius: var(--pal-border-radius);
}


ion-popover {
    //--box-shadow: $box-shadow;
    --width: fit-content;
  
    .popover-viewport {
      background: white;
      // padding-bottom: 1rem;
      border-radius: var(--pal-border-radius);
  
      ion-item::part(native) .item-inner {
        border: none !important;
      }
  
      .clickable {
        cursor: pointer;
        text-decoration: underline;
        color: var(--ion-color-primary);
      }
  
      .setting-actions {
        ion-list {
          padding-bottom: 1rem;
        }
        padding-bottom: 1rem;
      }
  
      
  
      ion-item {
        font-size: 1.1rem;
        color: var(--nb-primary);
        // height: 2.2rem;
  
        ion-label {
          margin-right: 0.5rem;
        }
      }
    }
  }

  ion-select {
    --border-radius: var(--pal-border-radius);
    
  }

  

  ion-icon {
    --ionicon-stroke-width: 17px;
    // color: var(--pal-text-color);
  }

  .as-split-gutter {
    flex-basis: 3px !important;
  }
  .as-no-resize {
    .as-split-gutter {
        .as-split-gutter-icon {
            display: none !important;
        }
    }
  }

  .ql-toolbar.ql-snow { border: none !important;}
  .ql-container.ql-snow { border: none !important;}

@media (max-width: ($screen-sm - 1px)) {
    .hide-xs {
        display: none !important;
    }
}
@media (max-width: ($screen-md - 1px)) {
    .web {
        display: none !important;
    }
    .hide-sm-and-below {
        display: none !important;
    }
}
@media (max-width: ($screen-lg - 1px)) {
    .hide-md-and-below {
        display: none !important;
    }
}
@media (max-width: ($screen-xl - 1px)) {
    .hide-lg-and-below {
        display: none !important;
    }
}



@media (min-width: $screen-xl) {
    .hide-xl {
        display: none !important;
    }
}
@media (min-width: $screen-lg) {
    .hide-lg-and-above {
        display: none !important;
    }
}
@media (min-width: $screen-md) {
    .mobile {
        display: none !important;
    }
    .hide-md-and-above {
        display: none !important;
    }
}
@media (min-width: $screen-sm) {
    .hide-sm-and-above {
        display: none !important;
    }
}

@font-face {
    font-family: 'Open Sans Variable';
    src: url('./assets/fonts/Open_Sans/OpenSans-VariableFont_wdth\,wght.ttf') format('truetype-variations');
    src: url('./assets/fonts/Open_Sans/OpenSans-VariableFont_wdth\,wght.ttf') format('truetype');
    font-weight: 100 900; /* Adjust based on your font's weight range */
    font-stretch: 25% 151%; /* Adjust if your font supports stretching */
}

@font-face {
    font-family: 'Open Sans Variable';
    src: url('./assets/fonts/Open_Sans/OpenSans-Italic-VariableFont_wdth\,wght.ttf') format('truetype-variations');
    src: url('./assets/fonts/Open_Sans/OpenSans-Italic-VariableFont_wdth\,wght.ttf') format('truetype');
    font-weight: 100 900; /* Adjust based on your font's weight range */
    font-stretch: 25% 151%; /* Adjust if your font supports stretching */
    font-style: italic;
}

body::-webkit-scrollbar {
    background-color: #fff;
    width: 16px;
}

/* background of the scrollbar except button or resizer */
body::-webkit-scrollbar-track {
    background-color: #fff;
}

/* scrollbar itself */
body::-webkit-scrollbar-thumb {
    background-color: #babac0;
    border-radius: 16px;
    border: 4px solid #fff;
}

/* set button(top and bottom of the scrollbar) */
body::-webkit-scrollbar-button {
    display:none;
}



